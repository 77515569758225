import NavBar from "./NavBar";
import {Button, Container} from "@mui/material";
import React, {useState} from "react";
import {FriendsTableProps} from "./types";
import FriendsTable from "./FriendsTable";

const FriendsPage : React.FC<FriendsTableProps> = ({ friends }) => {
    return(
        <>
            <NavBar/>
            <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                <FriendsTable friends={friends} />
            </Container></>
    )
}

export default FriendsPage;