import React, { createContext, useContext, useState, ReactNode, FunctionComponent } from 'react';
import {Group, Transaction, SplitsyPeasyUser} from "../types";

interface SharedState {
    // Define the shape of the state that you want to share across your app
    user?: SplitsyPeasyUser;
    transactions?: Transaction[];
    groups?: Group[];
}

// Define the shape of the context including functions or any setters
interface AppContextType {
    sharedState: SharedState;
    setSharedState: React.Dispatch<React.SetStateAction<SharedState>>;
}

// Creating the context with a default value that matches the AppContextType
// This avoids the need to assert the context as undefined initially and deal with potential undefined values later
export const AppContext = createContext<AppContextType>({
    sharedState: {}, // Provide a default empty state or initial value as needed
    setSharedState: () => {}, // Placeholder function
});

export function useAppContext() {
    // Directly use the context without needing to check for undefined
    return useContext(AppContext);
}

interface AppProviderProps {
    children: ReactNode;
}

export function AppProvider({ children}  : AppProviderProps) {
    const [sharedState, setSharedState] = useState({}); // Initialize your shared state here

    // Value that will be shared across components
    const value = {
        sharedState,
        setSharedState,
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
