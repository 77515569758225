import NavBar from "./NavBar";
import {Container} from "@mui/material";
import DataTables from "./DataTables";
import React, {useEffect} from "react";
import {DataTablesProps, SplitsyPeasyUser} from "./types";
import {useAppContext} from "./context/AppContext";
import {useAuth0, User} from "@auth0/auth0-react";
import {fetchOrCreateUserOnAppLogin} from "./apiHelpers/helpers";

const HomePage : React.FC<DataTablesProps> = ({ groups, transactions }) => {
    const { sharedState, setSharedState } = useAppContext();
    const { user, isAuthenticated } = useAuth0();
    const updateUser = (user: SplitsyPeasyUser) => {
        setSharedState(prevState => ({
            ...prevState,
            user: user
        }));
    };

    useEffect(() => {
        if (user) {
            console.log(user)
            fetchOrCreateUserOnAppLogin(user).then(splitsyUser => {
                updateUser(splitsyUser)
            });
        }
    }, [user]);

    return(
        <>
        <NavBar/>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <DataTables
                groups={groups || sharedState.groups}
                transactions={transactions || sharedState.transactions}
            />
        </Container>
        </>
    )
}

export default HomePage;