import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { CreateTransactionDialogProps } from './types';
import {useAuth0} from "@auth0/auth0-react";

const CreateTransactionDialog: React.FC<CreateTransactionDialogProps> = ({ open, onClose, onSubmit }) => {
    const { user } = useAuth0();
    const [name, setName] = useState('');
    const [creator, setCreator] = useState('');
    const [description, setDescription] = useState('');
    const [totalAmount, setAmount] = useState(0);

    const handleSubmit = () => {
        onSubmit({ name, creator, description, totalAmount });
        // Reset form and close dialog
        setName('');
        setAmount(0);
        setCreator(user!.name ?? 'Unknown');
        setDescription('');
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create New Transaction</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Group Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="amount"
                    label="Amount"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={totalAmount}
                    onChange={(e) => setAmount(Number(e.target.value))}
                />
                <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateTransactionDialog;
