// src/components/NavBar.tsx
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Box } from '@mui/material';

const NavBar: React.FC = () => {
    return (
        <AppBar position="static">
            <Toolbar>
                <Box display="flex" flexGrow={1}>
                    <Button color="inherit" component={RouterLink} to="/">Home</Button>
                    <Button color="inherit" component={RouterLink} to="/groups">Groups</Button>
                    <Button color="inherit" component={RouterLink} to="/transactions">Transactions</Button>
                    <Button color="inherit" component={RouterLink} to="/friends">Friends</Button>
                    <Button color="inherit" component={RouterLink} to="/settings">Settings</Button>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
