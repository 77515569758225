import React, {useState} from 'react';
import { TransactionsTableProps } from './types';
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import AddUserToTransactionDialog from "./AddUserToTransactionDialog";


const TransactionsTable: React.FC<TransactionsTableProps> = ({ transactions }) => {
    const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);

    const openAddUserDialog = () => {
        setAddUserDialogOpen(true);
    };

    const closeAddUserDialog = () => {
        setAddUserDialogOpen(false);
    };

    const handleAddUserToTransaction = (userEmail: string) => {
        console.log('User add to Transaction:', userEmail);
        // Here you would typically handle adding the new group to your state or making an API call
    };
    return (
        <>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Transaction Name</TableCell>
                        <TableCell align="right">Creator</TableCell>
                        <TableCell align="right">Description</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Add User</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.map((transaction) => (
                        <TableRow key={transaction.name}>
                            <TableCell component="th" scope="row">{transaction.name}</TableCell>
                            <TableCell align="right">{transaction.creator}</TableCell>
                            <TableCell align="right">{transaction.information}</TableCell>
                            <TableCell align="right">{transaction.totalAmount}</TableCell>
                            <TableCell align="right">{<Button onClick={openAddUserDialog}>Add User</Button>}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <AddUserToTransactionDialog open={addUserDialogOpen} onClose={closeAddUserDialog} onSubmit={handleAddUserToTransaction} />
            </Table>
        </TableContainer></>
    );
};

export default TransactionsTable;