import React from 'react';
import { Grid, Paper } from '@mui/material';
import GroupsTable from './GroupsTable';
import TransactionsTable from './TransactionsTable';
import { DataTablesProps } from './types'; // Import the interface

const DataTables: React.FC<DataTablesProps> = ({ groups, transactions }) => {
    return (
        <>
            <Grid container spacing={3}>
                {/* Groups Section */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <h2>Groups</h2>
                        <GroupsTable groups={groups} />
                    </Paper>
                </Grid>
                {/* Transactions Section */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <h2>Transactions</h2>
                        <TransactionsTable transactions={transactions} />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default DataTables;
