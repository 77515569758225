import React from 'react';
import { Box, Container, Paper, Typography } from '@mui/material';
import LoginButton from './LoginButton';

const LoginPage: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'purple',
            }}
        >
            <Container maxWidth="xs">
                <Paper elevation={3} sx={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h5" component="h1" gutterBottom>
                        Splitsy Peasy
                    </Typography>
                    <LoginButton />
                </Paper>
            </Container>
        </Box>
    );
};

export default LoginPage;