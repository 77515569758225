import NavBar from "./NavBar";
import {Button, Container} from "@mui/material";
import React, {useState} from "react";
import {GroupsTableProps} from "./types";
import GroupsTable from "./GroupsTable";
import CreateGroupDialog from "./CreateGroupDialog";

const GroupsPage : React.FC<GroupsTableProps> = ({ groups }) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleSubmitGroup = (group: { name: string; creator: string; description: string }) => {
        console.log('Group Created:', group);
        // Here you would typically handle adding the new group to your state or making an API call
    };
    return(
        <>
        <NavBar/>
        <Container>
            <Button variant="contained" onClick={handleOpenDialog}>
                Create Group
            </Button>
            <CreateGroupDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                onSubmit={handleSubmitGroup}
            />
        </Container>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <GroupsTable groups={groups} />
        </Container></>
    )
}

export default GroupsPage;