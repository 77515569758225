// src/theme.ts
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#7851a9',
        },
        secondary: {
            main: '#4b3b76',
        },
        // Add other colors as needed
    },
    // You can also add custom components styling here
});

export default theme;
