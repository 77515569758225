import NavBar from "./NavBar";
import {Button, Container} from "@mui/material";
import React, {useState} from "react";
import {TransactionsTableProps} from "./types";
import TransactionsTable from "./TransactionsTable";
import CreateTransactionDialog from "./CreateTransactionDialog";

const TransactionsPage : React.FC<TransactionsTableProps> = ({ transactions }) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleSubmitTransaction = (transaction: { name: string; creator: string; description: string; totalAmount: number }) => {
        console.log('Transaction Created:', transaction);
        // Here you would typically handle adding the new group to your state or making an API call
    };
    return(
        <>
            <NavBar/>
            <Container>
                <Button variant="contained" onClick={handleOpenDialog}>
                    Create Transaction
                </Button>
                <CreateTransactionDialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    onSubmit={handleSubmitTransaction}
                />
            </Container>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <TransactionsTable transactions={transactions} />
        </Container></>
    )
}

export default TransactionsPage;