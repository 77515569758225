import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";
import {AppProvider} from "./context/AppContext";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Auth0Provider
        clientId="uUwKj4kHtRbTOinIz4OOzv0g8uYiWTZA"
        domain="dev-4orhrozuza48jnwb.us.auth0.com"
        authorizationParams={{
            redirect_uri: (process.env.REACT_APP_ENVIRONMENT === 'DEV' ?
                process.env.REACT_APP_LOCAL_REDIRECT : process.env.REACT_APP_PROD_REDIRECT) + 'home'
        }}>
    <AppProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </AppProvider>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
