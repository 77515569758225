import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"
import {
    BrowserRouter,
    Routes,
    Route, Navigate
} from "react-router-dom"
import Profile from "./Profile";
import LogoutButton from "./LogoutButton";
import {useAuth0} from "@auth0/auth0-react";
import NotFoundPage from "./NotFoundPage";
import {Group, Transaction, SplitsyPeasyUser} from './types';
import {ThemeProvider} from "@mui/material";
import theme from "./theme";
import HomePage from "./Home";
import GroupsPage from "./GroupsPage";
import TransactionsPage from "./TransactionsPage";
import FriendsPage from "./FriendsPage";
import LoginPage from "./LoginPage";

const mockGroups: Group[] = [
    { name: 'Group A', creator: 'Sunny', description: 'Description A' },
    { name: 'Group B', creator: 'Caitlin', description: 'Description B' },
    // Add more mock groups as needed
];

const mockTransactions: Transaction[] = [
    { name: 'Transaction A', creator: 'Alexis', information: 'Information A' , totalAmount: 154.15, singleTransaction: true},
    { name: 'Transaction B', creator: 'Sunny', information: 'Information B' , totalAmount: 160, singleTransaction: false},
    // Add more mock transactions as needed
];

const mockFriends: SplitsyPeasyUser[] = [
    { name: 'Alexis Hou', email: "alexis7hou@gmail.com", username: "Wolfclaw"},
    { name: 'Caitlin Colina', email: "catawin@gmail.com", username: "CatjaLater"},
    // Add more mock transactions as needed
]



function App() {
  const { isAuthenticated } = useAuth0();
  return (
      <ThemeProvider theme={theme}>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Navigate replace to={isAuthenticated ? "/home" : "/login"}/> } />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/home" element={<HomePage groups={mockGroups} transactions={mockTransactions} />} />
              <Route path ="/settings" element={<Profile />}/>
              <Route path ="/logout" element={<LogoutButton />}/>
              <Route path="/groups" element={<GroupsPage groups={mockGroups}/>} />
              <Route path="/transactions" element={<TransactionsPage transactions={mockTransactions}/>} />
              <Route path="/friends" element={<FriendsPage friends={mockFriends}/>} />
              <Route path = "*" element={<NotFoundPage />} />
          </Routes>
      </BrowserRouter>
      </ThemeProvider>
  )
}

export default App