import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {Avatar, Box, Paper, Typography} from "@mui/material";

const Profile = () => {
    const { user, isAuthenticated } = useAuth0();
    return (
        (isAuthenticated && user != undefined) &&
        <Paper elevation={3}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    marginTop: 8,
                }}
            >
                <Avatar
                    alt="Profile Picture"
                    src={user!.picture}
                    sx={{ width: 100, height: 100 }}
                />
                <Typography variant="h5">
                    {user!.name}
                </Typography>
                <Typography variant="body1">{user!.email}</Typography>
            </Box>
        </Paper>
    );
};

export default Profile;