import {SplitsyPeasyUser} from "../types";
import {User} from "@auth0/auth0-react";

export async function fetchOrCreateUserOnAppLogin(user: User): Promise<SplitsyPeasyUser> {
    console.log("Trying to get user")
    const response = await fetch('/user?email='+user?.email,);
    if (response.status == 404) {
        return createUser(user)
    }
    const data: SplitsyPeasyUser = await response.json();
    return data;
}

export async function createUser(user: User) : Promise<SplitsyPeasyUser> {
    console.log("Trying to create user")
    const response = await fetch('/users',
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body:JSON.stringify({
                "Name": user?.name,
                "Email": user?.email
            })
        }
    );

    console.log("Created user")

    if (response.status == 404) {
        throw new Error('Network response was not ok: ' + response.body);
    }
    const data: SplitsyPeasyUser = await response.json();
    return data;
}