// src/components/CreateGroupDialog.tsx
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { CreateGroupDialogProps } from './types';

const CreateGroupDialog: React.FC<CreateGroupDialogProps> = ({ open, onClose, onSubmit }) => {
    const [name, setName] = useState('');
    const [creator, setCreator] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = () => {
        onSubmit({ name, creator, description });
        // Reset form and close dialog
        setName('');
        setCreator('');
        setDescription('');
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create New Group</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Group Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="creator"
                    label="Creator"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={creator}
                    onChange={(e) => setCreator(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateGroupDialog;
