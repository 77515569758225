import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import {AddUserToTransactionDialogProps} from './types';
import {useAuth0} from "@auth0/auth0-react";

const AddUserToTransactionDialog: React.FC<AddUserToTransactionDialogProps> = ({ open, onClose, onSubmit }) => {
    const [userEmail, setUserEmail] = useState('');
    const handleSubmit = () => {
        onSubmit(userEmail);
        // Reset form and close dialog
        setUserEmail('');
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add User To Transaction</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="amount"
                    label="Email"
                    type="string"
                    fullWidth
                    variant="standard"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Add</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddUserToTransactionDialog;
