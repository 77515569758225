// src/components/GroupsTable.tsx
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { GroupsTableProps } from './types';


const GroupsTable: React.FC<GroupsTableProps> = ({ groups }) => {
    return (
        <>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Group Name</TableCell>
                        <TableCell align="right">Creator</TableCell>
                        <TableCell align="right">Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groups.map((group) => (
                        <TableRow key={group.name}>
                            <TableCell component="th" scope="row">{group.name}</TableCell>
                            <TableCell align="right">{group.creator}</TableCell>
                            <TableCell align="right">{group.description}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer></>
    );
};

export default GroupsTable;
