import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { FriendsTableProps } from './types';


const FriendsTable: React.FC<FriendsTableProps> = ({ friends }) => {
    return (
        <>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Email</TableCell>
                        <TableCell align="right">Username</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {friends.map((friend) => (
                        <TableRow key={friend.name}>
                            <TableCell component="th" scope="row">{friend.name}</TableCell>
                            <TableCell align="right">{friend.email}</TableCell>
                            <TableCell align="right">{friend.username}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer></>
    );
};

export default FriendsTable;
